import { Fragment } from "react";

import "../styles/PlaceHolder.scss";

type PlaceHolderParams = {
    image: string;
    title?: string;
    subTitle?: string;
    height?: string;
    width?: string;
    bgAttachment?: string;
    opacity?: number;
    heading?: JSX.Element;
};

export const PlaceHolder: React.FC<PlaceHolderParams> = ({
    height,
    width,
    image,
    title,
    bgAttachment,
    opacity,
    heading,
}) => {
    return (
        <Fragment>
            <section
                className="place-holder flex center vcenter parallax"
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundAttachment: bgAttachment ?? "unset",
                    height: `${height ?? "500px"}`,
                    width: `${width ?? 100}%`,
                    opacity: opacity ?? 0.8,
                }}
            >
                {heading ? (
                    <Fragment>{heading}</Fragment>
                ) : (
                    <Fragment>
                        {title && <h1 className="title center">{title}</h1>}
                    </Fragment>
                )}
            </section>
        </Fragment>
    );
};
