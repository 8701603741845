import { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BlogPost } from "../../@types/blogPost";
import { VlogPost } from "../../@types/vlogPost";
import { getAllBlogs, getAllVlogs } from "../../apiClients/contentApiClient";
import { PrimaryImage } from "../shared/PrimaryImage";
import { BlogPreview } from "./BlogPreview";
import { VlogPreview } from "./VlogPreview";

export function BlogsAndVlogs(props: any) {
    const [allBlogPosts, setAllBlogPosts] = useState<Array<BlogPost>>([]);
    const [allVlogPosts, setAllVlogPosts] = useState<Array<VlogPost>>([]);

    const loadData = async () => {
        let bp = await getAllBlogs();
        setAllBlogPosts(bp);

        let vp = await getAllVlogs();
        setAllVlogPosts(vp);
    };

    useEffect(() => {
        // Get all blogs
        loadData();
    }, []);

    return (
        <section id="main_content" className="main-page">
            <PrimaryImage
                image="/images/1018-blur-grayscale.jpg"
                title="Blogs & Vlogs"
                height={"500px"}
            />
            <Container style={{ paddingBottom: 50 }}>
                <Row>
                    <Col xs={6}>
                        <Container
                            className="center vcenter"
                            style={{ width: "200px", marginTop: 50 }}
                        >
                            <h2 className="underline">Blogs</h2>
                            <h5>{allBlogPosts.length} Articles</h5>
                        </Container>
                        {allBlogPosts?.map((post, index) => {
                            return (
                                <Fragment key={index}>
                                    <BlogPreview
                                        post={post}
                                        index={index}
                                        mt={20}
                                    />
                                </Fragment>
                            );
                        })}
                    </Col>
                    <Col xs={6}>
                        <Container
                            className="center vcenter"
                            style={{ width: "200px", marginTop: 50 }}
                        >
                            <h2 className="underline">Vlogs</h2>
                            <h5>{allVlogPosts.length} Videos</h5>
                        </Container>
                        <Container className="center vcenter">
                            {allVlogPosts?.map((post, index) => {
                                return (
                                    <Fragment key={index}>
                                        <VlogPreview
                                            post={post}
                                            index={index}
                                            mt={20}
                                        />
                                    </Fragment>
                                );
                            })}
                        </Container>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
