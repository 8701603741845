import { configureStore } from "@reduxjs/toolkit";

import navReducer from "./navigation/NavSlice";
import dataReducer from "./data/DataSlice";

export const store = configureStore({
    reducer: {
        nav: navReducer,
        data: dataReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
