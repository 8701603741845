import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { VlogPost } from "../../@types/vlogPost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../styles/BlogSummary.scss";

type VlogPreviewParams = {
    post: VlogPost;
    index: number;
    mt: number;
    maxHeight?: string;
    maxWidth?: string;
};

export const VlogPreview: React.FC<VlogPreviewParams> = ({
    post,
    index,
    mt,
    maxHeight,
    maxWidth,
}) => {
    let link = `/vlog/${post.id}`;

    return (
        <Row
            key={index}
            className="vlog-preview"
            style={{
                marginTop: `${mt}px`,
                maxHeight: maxHeight ?? "unset",
                maxWidth: maxWidth ?? "unset",
            }}
        >
            <Col md={3} lg={3} className="text-center">
                <div className="blog-icon">
                    <img
                        className="icon"
                        alt="project name"
                        src="https://via.placeholder.com/250/eac76e/ffffff/?text=Vlog"
                    />
                </div>
            </Col>
            <Col md={9} lg={9} className="text-left p-3 p-md-0">
                <div className="title">{post.title}</div>
            </Col>
            <Col md={12} className="text-left">
                <p
                    className={`excerpt ${
                        post.excerpt.length > 100 ? "snippet" : ""
                    }`}
                >
                    {post.excerpt}
                </p>
            </Col>
            <Col md={12} className="center mt-3">
                <Link to={link} className="btn btn-sm btn-outline-primary">
                    Read More
                    <FontAwesomeIcon
                        icon={["fas", "chevron-right"]}
                        style={{ marginLeft: 5 }}
                    />
                </Link>
            </Col>
        </Row>
    );
};
