import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

export const Vision: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <section className="content">
      <Row className="vision-row">
        <Col sm={12} md={5}>
          <div className="vision-header">
            <h1>Vision</h1>
          </div>
        </Col>
        <Col sm={12} md={7}>
          <div className="row">
            <div className="col-12">
              <div className="vision-content">
                <h3>We contribute useful code</h3>
                <p>
                  so that developers can more easily build systems and products.
                  We provide tools that make solving business problems easier.
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};
