import { Fragment, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

import { Project } from "../../@types/project";
import { getAllProjects } from "../../apiClients/contentApiClient";
import { ProjectRow } from "./ProjectRow";

import "../styles/ProjectList.scss";

type ProjectSummaryParams = {
    layout: string;
    height?: number;
    width?: number;
};

export const ProjectList: React.FC<ProjectSummaryParams> = ({
    layout,
    height,
    width,
}) => {
    const [allProjects, setAllProjects] = useState<Array<Project>>([]);

    const loadData = async () => {
        let pjs = await getAllProjects();
        let sorted = [...pjs].sort(
            (a: any, b: any) => a.sequenceNum - b.sequenceNum
        );
        setAllProjects(sorted);
    };

    useEffect(() => {
        // Get all projects
        loadData();
    }, []);

    return (
        <div id="project_list">
            {layout === "full" ? (
                <Fragment>
                    {allProjects?.length > 0 &&
                        allProjects.map((project: Project, index: number) => (
                            <Container key={index} className="project-preview">
                                <ProjectRow project={project} />
                            </Container>
                        ))}
                </Fragment>
            ) : (
                <Container>
                    <Row>
                        {allProjects?.length > 0 &&
                            allProjects.map(
                                (project: Project, index: number) => (
                                    <Col
                                        key={index}
                                        sm="12"
                                        md="6"
                                        lg="4"
                                        className="center"
                                    >
                                        <div
                                            className={`project-preview ${layout}`}
                                        >
                                            <ProjectRow
                                                project={project}
                                                layout={layout}
                                            />
                                        </div>
                                    </Col>
                                )
                            )}
                    </Row>
                </Container>
            )}
        </div>
    );
};
