import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BlogPost } from "../../@types/blogPost";
import { VlogPost } from "../../@types/vlogPost";

import { getAllBlogs, getAllVlogs } from "../../apiClients/contentApiClient";

import { StickySection } from "../shared/StickySection";
import { BlogPreview } from "../blog/BlogPreview";
import { VlogPreview } from "../blog/VlogPreview";

import "../styles/BlogSummary.scss";

type BlogSummaryParams = {
    height?: number;
    width?: number;
};

export const BlogSummary: React.FC<BlogSummaryParams> = ({ height, width }) => {
    const [allBlogPosts, setAllBlogPosts] = useState<Array<BlogPost>>([]);
    const [allVlogPosts, setAllVlogPosts] = useState<Array<VlogPost>>([]);

    const loadData = async () => {
        let bp = await getAllBlogs();
        bp = bp.filter((p) => p.featured);
        setAllBlogPosts(bp);

        let vp = await getAllVlogs();
        vp = vp.filter((p) => p.featured);
        setAllVlogPosts(vp);
    };

    useEffect(() => {
        // Get all projects
        loadData();
    }, []);

    return (
        <StickySection
            peerStyle={{
                zIndex: 100,
                borderLeftWidth: "1px",
                borderLeftColor: "#ffffff",
            }}
            stickyContent={
                <Fragment>
                    <div id="header">
                        <span className="title">
                            Blogs
                            <br />
                            &
                            <br />
                            Vlogs
                        </span>
                        <span className="mobile-title">Blogs & Vlogs</span>
                        <div className="center" style={{ marginTop: 25 }}>
                            <Link
                                to="/blog"
                                className="btn btn-sm btn-outline-primary"
                                style={{ fontSize: "14pt" }}
                            >
                                View All Blogs & Vlogs
                                <FontAwesomeIcon
                                    icon={["fas", "chevron-right"]}
                                    style={{ marginLeft: 5 }}
                                />
                            </Link>
                        </div>
                    </div>
                </Fragment>
            }
            peerContent={
                <div id="blog_list">
                    <Row>
                        <Col md={12} lg={6}>
                            {allBlogPosts.map((post, index) => {
                                // if (index % 2 !== 0) return;

                                let mt = 0;
                                if (index === 0) {
                                    mt = -65; // -125 for overrage effect
                                } else {
                                    mt = 100;
                                }
                                return (
                                    <Fragment key={index}>
                                        <BlogPreview
                                            post={post}
                                            index={index}
                                            mt={mt}
                                        />
                                    </Fragment>
                                );
                            })}
                        </Col>
                        <Col md={12} lg={6}>
                            {allVlogPosts.map((post, index) => {
                                // if (index % 2 === 0) return;

                                let mt = 0;
                                if (index === 1) {
                                    mt = 50;
                                } else {
                                    mt = 75;
                                }
                                return (
                                    <Fragment key={index}>
                                        <VlogPreview
                                            post={post}
                                            index={index}
                                            mt={mt}
                                        />
                                    </Fragment>
                                );
                            })}
                        </Col>
                    </Row>
                </div>
            }
        ></StickySection>
    );
};
