import { Fragment, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import useInterval from "react-useinterval";
import { Contributor } from "../../@types/githubContribor";
import { TeamMember } from "../../@types/teamMember";
import { RootState } from "../../store";

import "../styles/ProjectList.scss";

type ProjectHeaderParams = {
    bgImage?: string;
    bgColor?: string;
};

const ContributorDot: React.FC<ProjectHeaderParams> = () => {
    const { contributors } = useSelector((state: RootState) => state.data);

    const [delay, setDelay] = useState<number>(2500);
    const [contributor, setContributor] = useState<Contributor | undefined>(
        undefined
    );

    useEffect(() => {
        // Random delay between 2.5 and 10 seconds
        let min = 2500;
        let max = 10000;

        let random = Math.floor(Math.random() * (max - min)) + min;
        setDelay(random);
    }, []);

    useEffect(() => {
        if (contributors) {
            changeContributor();
        }
    }, [contributors]);

    const changeContributor = () => {
        var randContributor =
            contributors[Math.floor(Math.random() * contributors.length)];
        setContributor(randContributor);
    };

    useInterval(changeContributor, delay);

    return (
        <Fragment>
            {contributor ? (
                <ProjectDot bgImage={`${contributor.avatar_url}&size=42`} />
            ) : (
                <ProjectDot bgImage="https://github.com/tcdi.png?size=42" />
            )}
        </Fragment>
    );
};

const ProjectDot: React.FC<ProjectHeaderParams> = ({ bgImage, bgColor }) => {
    if (bgImage) {
        return (
            <div
                className="project-dot"
                style={{ backgroundImage: `url(${bgImage})` }}
            >
                &nbsp;
            </div>
        );
    }
    return (
        <div
            className="project-dot"
            style={{ backgroundColor: bgColor ?? "transparent" }}
        ></div>
    );
};

type TeamMemberProps = {
    id: string;
};

const TeamMemberDot: React.FC<TeamMemberProps> = ({ id }) => {
    const { teamMembers } = useSelector((state: RootState) => state.data);

    const [teamMember, setTeamMember] = useState<TeamMember | undefined>(
        undefined
    );

    useEffect(() => {
        let tm = teamMembers.find((t) => t.id === id);
        setTeamMember(tm);
    }, [teamMembers]);

    return (
        <Fragment>
            {teamMember ? (
                <ProjectDot bgImage={teamMember.imageUrl} />
            ) : (
                <ProjectDot bgImage="https://github.com/tcdi.png?size=42" />
            )}
        </Fragment>
    );
};

export const ProjectHeader: React.FC<ProjectHeaderParams> = () => {
    return (
        <Fragment>
            <div id="project_header" className="float-left">
                <Row>
                    <Col xs="6">
                        <div className="header-title">
                            <h1 className="title">
                                Open
                                <br />
                                Source
                            </h1>
                        </div>
                    </Col>
                    <Col xs="6">
                        <div className="header-art">
                            <Row>
                                <Col xs="2">
                                    <ProjectDot bgColor="#b8ebed" />
                                </Col>
                                <Col xs="2">
                                    <ContributorDot />
                                </Col>
                                <Col xs="2">
                                    <ProjectDot bgColor="#ffffff" />
                                </Col>
                                <Col xs="2">
                                    <ContributorDot />
                                    {/* <ProjectDot bgColor="#1e5f86" /> */}
                                </Col>
                                <Col xs="2">
                                    <ProjectDot bgColor="#93adc3" />
                                </Col>
                                <Col xs="2">
                                    <ContributorDot />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2"></Col>
                                <Col xs="2">
                                    <ProjectDot bgColor="#313131" />
                                </Col>
                                <Col xs="2">
                                    <ContributorDot />
                                </Col>
                                <Col xs="2">
                                    <ProjectDot bgColor="#fee4ac" />
                                </Col>
                                <Col xs="2">
                                    <ContributorDot />
                                </Col>
                                <Col xs="2">
                                    <ProjectDot bgColor="#e1f8f7" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2"></Col>
                                <Col xs="2"></Col>
                                <Col xs="2">
                                    <ProjectDot bgColor="#865fb7" />
                                </Col>
                                <Col xs="2">
                                    <ContributorDot />
                                </Col>
                                <Col xs="2">
                                    <ProjectDot bgColor="#c9cdd1" />
                                </Col>
                                <Col xs="2">
                                    <ContributorDot />
                                    {/* <ProjectDot bgColor="#00ccce" /> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2"></Col>
                                <Col xs="2"></Col>
                                <Col xs="2"></Col>
                                <Col xs="2">
                                    <ProjectDot bgColor="#fcfcfd" />
                                </Col>
                                <Col xs="2">
                                    <ContributorDot />
                                </Col>
                                <Col xs="2">
                                    <ProjectDot bgColor="#485862" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2"></Col>
                                <Col xs="2"></Col>
                                <Col xs="2"></Col>
                                <Col xs="2"></Col>
                                <Col xs="2">
                                    <ProjectDot bgColor="#d6c7e6" />
                                </Col>
                                <Col xs="2">
                                    <ContributorDot />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2"></Col>
                                <Col xs="2"></Col>
                                <Col xs="2"></Col>
                                <Col xs="2"></Col>
                                <Col xs="2"></Col>
                                <Col xs="2">
                                    <ContributorDot />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};
