import { useRef } from "react";

import "./StickySection.scss";

type Props = {
    layout?: string;
    className?: string;
    stickyContent: JSX.Element;
    stickyStyle?: any;
    peerContent: JSX.Element;
    peerStyle?: any;
};

export const StickySection: React.FC<Props> = ({
    layout,
    stickyContent: stickContent,
    stickyStyle,
    peerContent,
    peerStyle,
}) => {
    const stickyRef = useRef<any>();
    const peerRef = useRef<any>();

    const stackLayout = () => {
        return (
            <div>
                <div
                    className="sticky-section sticky-header"
                    style={stickyStyle}
                    ref={stickyRef}
                >
                    {stickContent}
                </div>
                <div className="peer-section" style={peerStyle} ref={peerRef}>
                    {peerContent}
                </div>
            </div>
        );
    };
    return stackLayout();
};
