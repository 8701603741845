import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";

import { ParallaxBanner } from "react-scroll-parallax";
import { BannerLayer } from "react-scroll-parallax/dist/components/ParallaxBanner/types";

import { RootState } from "../../store";

import "../styles/PrimaryImage.css";

type PrimaryImageParams = {
    image?: string;
    title?: string;
    subTitle?: string;
    height?: string;
    width?: string;
    opacity?: number;
    heading?: JSX.Element;
};

export const PrimaryImage: React.FC<PrimaryImageParams> = ({
    image,
    title,
    subTitle,
    height,
    width,
    heading,
}) => {
    const { activePage } = useSelector((state: RootState) => state.nav);

    useEffect(() => {}, [activePage]);

    const background: BannerLayer = {
        image: image,
        translateY: [0, 50],
        opacity: [0.75, 0.25],
        scale: [1.05, 1, "easeOutCubic"],
        shouldAlwaysCompleteAnimation: true,
    };

    const headline: BannerLayer = {
        translateY: [0, 30],
        scale: [1, 1.05, "easeOutCubic"],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: (
            <div className="inset center vcenter flex">
                {heading ? (
                    <Fragment>{heading}</Fragment>
                ) : (
                    <Fragment>
                        {title && <h1 className="title white">{title}</h1>}
                    </Fragment>
                )}
            </div>
        ),
    };

    const gradientOverlay: BannerLayer = {
        opacity: [0, 1, "easeOutCubic"],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: <div className="gradient inset" />,
    };

    return (
        <ParallaxBanner
            layers={[background, headline, gradientOverlay]}
            className="full"
            style={{ height: height, width: width }}
        />
    );
};
