import { Project } from "../@types/project";
import { TeamMember } from "../@types/teamMember";
import { BlogPost } from "../@types/blogPost";
import { VlogPost } from "../@types/vlogPost";
import { Contributor } from "../@types/githubContribor";

const base64 = require("base-64");

export const contentRoot =
    "https://tcdicontent.blob.core.windows.net/tcdioss/content";

export const getAllProjects = async () => {
    try {
        let response = await fetch(`${contentRoot}/jsonData/projects.json`, {
            method: "get",
        });
        let jsonResp = await response.json();
        return jsonResp as Array<Project>;
    } catch (error) {
        console.error("getAllItems", error);
        return Promise.reject(error);
    }
};

export const getAllTeamMembers = async () => {
    try {
        let response = await fetch(`${contentRoot}/jsonData/teamMembers.json`, {
            method: "get",
        });
        let jsonResp = await response.json();
        return jsonResp as Array<TeamMember>;
    } catch (error) {
        console.error("getAllItems", error);
        return Promise.reject(error);
    }
};

export const getAllVlogs = async () => {
    try {
        let response = await fetch(`${contentRoot}/jsonData/vlogData.json`, {
            method: "get",
        });
        let jsonResp = await response.json();
        return jsonResp as Array<VlogPost>;
    } catch (error) {
        console.error("getAllItems", error);
        return Promise.reject(error);
    }
};

export const getAllBlogs = async () => {
    try {
        let response = await fetch(`${contentRoot}/jsonData/blogData.json`, {
            method: "get",
        });
        let jsonResp = await response.json();
        return jsonResp as Array<BlogPost>;
    } catch (error) {
        console.error("getAllBlogs", error);
        return Promise.reject(error);
    }
};

export const getContributors = async (projectId: string) => {
    try {
        let url = `https://api.github.com/repos/tcdi/${projectId}/contributors`;
        let response = await fetch(url, {
            method: "get",
        });
        let jsonResp = await response.json();
        return jsonResp as Array<Contributor>;
    } catch (error) {
        console.error(error);
        return Promise.reject(error);
    }
};
