import { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import logo from "../../assets/images/logo_white.png";

import "./Header.scss";

export function Header() {
    const location = useLocation();

    const [scrollPosition, setScrollPosition] = useState(0);
    const [menuExpanded, setMenuExpanded] = useState<boolean>(false);

    const toggleMenu = () => {
        setMenuExpanded(!menuExpanded);
    };

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const scrollToSection = (sectionId: string) => {
        if (sectionId === "home") {
            let options: ScrollToOptions = {
                top: 0,
                behavior: "auto",
            };
            window.scrollTo(options);
            return;
        }

        const domElemById = document.getElementById(sectionId);
        if (domElemById) {
            let offsetTop = domElemById?.offsetTop - 65;
            offsetTop = Math.max(0, offsetTop);
            let options: ScrollToOptions = {
                top: offsetTop,
                behavior: "auto",
            };
            window.scrollTo(options);
        }
    };

    useEffect(() => {
        // Init Google Analytcs
        ReactGA.initialize("G-2MP309J7HD");

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    useEffect(() => {
        let options = {
            hitType: "pageview",
            page: location.pathname + location.hash + location.search,
            title: window.document.title,
        };
        console.log("options", options);
        ReactGA.send(options);

        if (location.hash) {
            setTimeout(() => {
                scrollToSection(location.hash.substring(1));
            }, 250);
        } else {
            window.scrollTo({ top: 0 });
        }
        setMenuExpanded(false);
    }, [location]);

    let navbarClass = `fixed-top ${scrollPosition === 0 ? "" : "scrolled"}`;
    return (
        <Navbar
            collapseOnSelect
            className={navbarClass}
            expand="lg"
            bg="dark"
            variant="dark"
            expanded={menuExpanded}
        >
            <Container fluid>
                <Navbar.Brand>
                    <Link className="nav-link" to="/#home">
                        <img src={logo} alt="logo" className="app-logo" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={toggleMenu}
                />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav>
                        <Link className="nav-link" to="/#home">
                            Home
                        </Link>
                        <Link className="nav-link" to="/#vision">
                            Vision
                        </Link>
                        <Link className="nav-link" to="/#blog_summary">
                            Blogs & Vlogs
                        </Link>
                        <Link className="nav-link" to="/#project_summary">
                            Open Source
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
