import { ProjectList } from "./ProjectList";
import { ProjectHeader } from "./ProjectHeader";

export function Projects(props: any) {
    return (
        <section id="main_content" className="main-page">
            <ProjectHeader />
            <section
                id="project_summary"
                style={{
                    paddingTop: 40,
                    backgroundColor: "#ffffff",
                }}
            >
                <ProjectList layout="stacked" />
            </section>
        </section>
    );
}
