import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.scss";

export function Footer(props: any) {
    return (
        <footer className="left">
            <Container>
                <Row>
                    <Col xs={4} md={2} style={{ marginBottom: 10 }}>
                        <img
                            className="logo"
                            src="https://www.tcdi.com/wp-content/uploads/2020/08/TCDI_Logo_White-Color_TCDI_Logo_White-Color.svg"
                            title="TCDI_Logo_White-Color_TCDI_Logo_White-Color"
                            alt="TCDI_Logo_White-Color_TCDI_Logo_White-Color"
                        />
                    </Col>
                    <Col xs={6} md={8}>
                        <Row>
                            <Col xs={12} md={4}>
                                <Row
                                    className="left"
                                    style={{ marginBottom: 10 }}
                                >
                                    <Col md="12" className="footer-headline">
                                        Greensboro, North Carolina
                                    </Col>
                                    <Col md="12">4508 Weybridge Lane</Col>
                                    <Col md="12">
                                        Greensboro, North Carolina 27407
                                    </Col>
                                    <Col md="12">
                                        Tel{" "}
                                        <a
                                            style={{ color: "#fff" }}
                                            href="tel:1.888.823.2820"
                                        >
                                            +1.888.823.2880
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <Row
                                    className="left"
                                    style={{ marginBottom: 10 }}
                                >
                                    <Col md="12" className="footer-headline">
                                        Cleveland, Ohio
                                    </Col>
                                    <Col md="12">The Bulkley Building</Col>
                                    <Col md="12">
                                        1501 Euclid Avenue, Suite 424
                                    </Col>
                                    <Col md="12">Cleveland, OH 44115</Col>
                                    <Col md="12">
                                        Tel{" "}
                                        <a
                                            style={{ color: "#fff" }}
                                            href="tel:1.216.664.1100"
                                        >
                                            +1.216.664.1100
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <Row
                                    className="left"
                                    style={{ marginBottom: 10 }}
                                >
                                    <Col md="12" className="footer-headline">
                                        Purchase, New York
                                    </Col>
                                    <Col md="12">3 Manhattan Road</Col>
                                    <Col md="12">1st Floor, Suite 106</Col>
                                    <Col md="12">Purchase, NY 10577</Col>
                                    <Col md="12">
                                        Tel{" "}
                                        <a
                                            style={{ color: "#fff" }}
                                            href="tel:1.914.517.6424"
                                        >
                                            +1.914.517.6424
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2} md={2}>
                        <Row style={{ marginBottom: 10 }}>
                            <Col xs={12} sm={6} className="center">
                                <a
                                    href="https://twitter.com/tcdioss"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon
                                        fontSize={36}
                                        icon={["fab", "twitter"]}
                                        style={{ margin: 5 }}
                                    />
                                    <br />
                                    <span className="elementor-screen-only">
                                        Twitter
                                    </span>
                                </a>
                            </Col>
                            <Col xs={12} sm={6} className="center">
                                <a
                                    href="https://github.com/tcdi"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon
                                        fontSize={36}
                                        icon={["fab", "github"]}
                                        style={{ margin: 5 }}
                                    />
                                    <br />
                                    <span className="elementor-screen-only">
                                        GitHub
                                    </span>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
