import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ProjectList } from "../projects/ProjectList";

import "../styles/ProjectList.scss";

type ProjectSummaryParams = {
    height?: number;
    width?: number;
};

export const ProjectSummary: React.FC<ProjectSummaryParams> = ({
    height,
    width,
}) => {
    return (
        <section className="content">
            <div>
                <div className="project-header sticky-header center">
                    <Row>
                        <Col xs="12">
                            <h1>Open Source</h1>
                            <h3 style={{ marginTop: "-20px" }}>
                                Happening Now!
                            </h3>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col md={12}>
                        <ProjectList layout="full" />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="center">
                        <Link
                            to={`/projects`}
                            className="btn btn-sm btn-outline-primary"
                            style={{ fontSize: "14pt" }}
                        >
                            Get Involved
                        </Link>
                    </Col>
                </Row>
            </div>
        </section>
    );
};
