import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "./Header";
import { Footer } from "./Footer";
import {
    getAllProjects,
    getAllTeamMembers,
    getContributors,
} from "../../apiClients/contentApiClient";
import {
    setContributors,
    setProjects,
    setTeamMembers,
} from "../../store/data/DataSlice";
import { Contributor } from "../../@types/githubContribor";
import { RootState } from "../../store";

export function Layout(props: any) {
    const dispatch = useDispatch();

    const { contributors, projects } = useSelector(
        (state: RootState) => state.data
    );

    const loadData = async () => {
        let projects = await getAllProjects();
        projects = [...projects].sort(
            (a: any, b: any) => a.sequenceNum - b.sequenceNum
        );
        dispatch(setProjects(JSON.stringify(projects)));

        let members = await getAllTeamMembers();
        members = [...members].sort(
            (a: any, b: any) => a.sequenceNum - b.sequenceNum
        );
        dispatch(setTeamMembers(JSON.stringify(members)));
    };

    const loadContributors = async () => {
        let all: Array<Contributor> = [];

        for (let i = 0; i < projects.length; i++) {
            let proj = projects[i];
            if (proj.githubId) {
                // Get the contributors
                let contributors = await getContributors(proj.githubId);
                if (contributors) {
                    contributors.forEach((item: Contributor) => {
                        let existing = contributors.find(
                            (tm) => tm.id === item.id
                        );
                        if (existing !== undefined) {
                            all = [...all, item];
                        }
                    });
                }
            }
        }
        dispatch(setContributors(JSON.stringify(all)));
    };

    useEffect(() => {
        // Get all data
        loadData();
    }, []);

    useEffect(() => {
        if (projects) {
            // Get all contributors
            loadContributors();
        }
    }, [projects]);

    return (
        <Fragment>
            <Header />
            <Fragment>{props.children}</Fragment>
            <Footer />
        </Fragment>
    );
}
