import { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ItemLink, Section, VlogPost } from "../../@types/vlogPost";

import { getAllVlogs } from "../../apiClients/contentApiClient";
import { PlaceHolder } from "../shared/PlaceHolder";

import { VlogPreview } from "./VlogPreview";

import "../styles/DisplayVlogPost.scss";

type PostParams = {
    postName: string;
};

type SectionParams = {
    section: Section;
};

const TextVlogSection: React.FC<SectionParams> = ({ section }) => {
    return (
        <div>
            <div className="title">{section.title}</div>
            <p className="content">{section.content}</p>
        </div>
    );
};

const HtmlVlogSection: React.FC<SectionParams> = ({ section }) => {
    return (
        <div>
            <div className="title">{section.title}</div>
            <p
                className="content"
                dangerouslySetInnerHTML={{
                    __html: section?.content ?? "",
                }}
            />
            {section.links && (
                <Fragment>
                    <div className="vlog-item">
                        <Row>
                            {section.links &&
                                section?.links.map(
                                    (item: ItemLink, index: number) => (
                                        <Col key={index} className="center">
                                            <Button
                                                className="item-btn"
                                                style={{
                                                    backgroundColor:
                                                        item.bgColor ??
                                                        "transparent",
                                                }}
                                                variant="text"
                                                onClick={(e) =>
                                                    window.open(item.url)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={[
                                                        item
                                                            .icon[0] as IconPrefix,
                                                        item
                                                            .icon[1] as IconName,
                                                    ]}
                                                    color={item.iconColor}
                                                    fontSize={
                                                        item.iconSize ?? 12
                                                    }
                                                    style={{
                                                        marginLeft: 5,
                                                    }}
                                                ></FontAwesomeIcon>
                                                <br />
                                                <strong
                                                    style={{
                                                        color: item.textColor,
                                                    }}
                                                >
                                                    {item.name}
                                                </strong>
                                            </Button>
                                        </Col>
                                    )
                                )}
                        </Row>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

const ItemsVlogSection: React.FC<SectionParams> = ({ section }) => {
    return (
        <div>
            {section.items &&
                section.items.map((item: any, index: number) => (
                    <Fragment key={index}>
                        <div className="vlog-item">
                            <Row>
                                <Col>
                                    <FontAwesomeIcon
                                        icon={[item.icon[0], item.icon[1]]}
                                        fontSize={item.iconSize ?? 12}
                                        style={{ marginLeft: 5 }}
                                    ></FontAwesomeIcon>
                                </Col>
                                <Col>{item.name}</Col>
                                <Col>{item.value}</Col>
                            </Row>
                        </div>
                    </Fragment>
                ))}
        </div>
    );
};

const DisplayVlogSection: React.FC<SectionParams> = ({ section }) => {
    if (section.type === "text") return <TextVlogSection section={section} />;
    if (section.type === "html") return <HtmlVlogSection section={section} />;
    if (section.type === "items") return <ItemsVlogSection section={section} />;

    return <Fragment />;
};

export const DisplayVlogPost: React.FC = () => {
    const { postName } = useParams<PostParams>();

    const [allVlogPosts, setAllVlogPosts] = useState<Array<VlogPost>>([]);
    const [vlogPost, setVlogPosts] = useState<VlogPost | null>(null);

    const loadData = async () => {
        let allPosts = await getAllVlogs();
        setAllVlogPosts(allPosts);
        let v = allPosts.find((x) => x.id === postName);
        if (v) {
            setVlogPosts(v);
        }
    };

    useEffect(() => {
        // Get all blogs
        loadData();
    }, [postName]);

    return (
        <section id="main_content" className="main-page">
            <PlaceHolder
                image={vlogPost?.bannerImgUrl ?? ""}
                title="Vlog"
                subTitle={vlogPost?.title}
                height={"500px"}
                heading={
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <h1 className="title white">Vlog</h1>
                        <h2 className="white">{vlogPost?.title}</h2>
                        <hr
                            style={{
                                color: "#FFFFFF",
                                height: 5,
                                width: 150,
                                marginLeft: "auto",
                                marginRight: "auto",
                                opacity: 1,
                                borderRadius: 10,
                            }}
                        />
                        <h4 className="white">
                            {vlogPost?.author} | {vlogPost?.publishedDate}
                        </h4>
                        <a
                            className="btn btn-lg"
                            href={vlogPost?.videoUrl}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                backgroundColor: "#FFFFFF",
                                color: "#707070",
                                marginTop: 15,
                                zIndex: 1000,
                            }}
                        >
                            <FontAwesomeIcon
                                icon={["fas", "circle-play"]}
                            ></FontAwesomeIcon>
                            &nbsp; Play Video
                        </a>
                    </div>
                }
            />
            {vlogPost && (
                <Container>
                    <div className="display-vlog">
                        <div className="return-header">
                            <Link to="/blog">Return to Blogs & Vlogs</Link>
                        </div>
                        <div className="vlog-main">
                            <Row>
                                <Col sm={12} md={7}>
                                    {vlogPost.sections
                                        .filter((s) => s.placement === "main")
                                        .map((section: any, index: number) => (
                                            <Fragment key={index}>
                                                <DisplayVlogSection
                                                    section={section}
                                                />
                                            </Fragment>
                                        ))}
                                </Col>
                                <Col
                                    sm={12}
                                    md={5}
                                    className="d-sm-none d-md-block"
                                >
                                    <div className="vlog-gutter">
                                        {vlogPost.sections
                                            .filter(
                                                (s) => s.placement === "gutter"
                                            )
                                            .map(
                                                (
                                                    section: any,
                                                    index: number
                                                ) => (
                                                    <Fragment key={index}>
                                                        <DisplayVlogSection
                                                            section={section}
                                                        />
                                                    </Fragment>
                                                )
                                            )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <hr />
                    <h3>Other Featured Vlogs</h3>
                    <section className="blog-row">
                        {allVlogPosts
                            ?.filter((x) => x.id !== vlogPost.id)
                            .map((post, index) => {
                                return (
                                    <VlogPreview
                                        key={index}
                                        post={post}
                                        index={index}
                                        mt={20}
                                        maxHeight="335px"
                                        maxWidth="400px"
                                    />
                                );
                            })}
                    </section>
                </Container>
            )}
        </section>
    );
};
