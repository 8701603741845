import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ParallaxProvider } from "react-scroll-parallax";

import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { store } from "./store";
import { Home } from "./pages/home/Home";
import { Projects } from "./pages/projects/Projects";

import { BlogsAndVlogs } from "./pages/blog/BlogsAndVlogs";
import { Layout } from "./pages/shared/Layout";
import { DisplayBlogPost } from "./pages/blog/DisplayBlogPost";
import { DisplayVlogPost } from "./pages/blog/DisplayVlogPost";

import "./App.scss";

library.add(far, fas, fab);

function App() {
    return (
        <ParallaxProvider>
            <div className="App">
                <Provider store={store}>
                    <BrowserRouter>
                        <Layout>
                            <Routes>
                                <Route
                                    path="/projects"
                                    element={<Projects />}
                                ></Route>
                                <Route
                                    path="/blog/:postName"
                                    element={<DisplayBlogPost />}
                                ></Route>
                                <Route
                                    path="/vlog/:postName"
                                    element={<DisplayVlogPost />}
                                ></Route>
                                <Route
                                    path="/blog"
                                    element={<BlogsAndVlogs />}
                                ></Route>
                                <Route path="/" element={<Home />}></Route>
                            </Routes>
                        </Layout>
                    </BrowserRouter>
                </Provider>
            </div>
        </ParallaxProvider>
    );
}

export default App;
