import { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Project } from "../../@types/project";

import "../styles/ProjectList.scss";

type ProjectParams = {
    project: Project;
    layout?: string;
};

export const ProjectRow: React.FC<ProjectParams> = ({ project, layout }) => {
    useEffect(() => {}, []);

    return (
        <Row className="project-summary">
            <Col
                sm={12}
                md={layout === "stacked" ? 12 : 3}
                className="column flex center"
            >
                <Row className="project-banner">
                    <Col md={12} className="flex center vcenter">
                        <div
                            className="project-icon"
                            style={{
                                backgroundImage: `url('${
                                    project.imageUrl ??
                                    "https://via.placeholder.com/250/ffffff/777777/?text=Placeholder"
                                }')`,
                            }}
                        ></div>
                    </Col>
                    <Col md={12}>
                        <div className="title">{project.title}</div>
                    </Col>
                </Row>
            </Col>
            <Col
                sm={12}
                md={layout === "stacked" ? 12 : 6}
                className="column center text-left"
            >
                <Container className="description">
                    {project.shortDescription}
                </Container>
            </Col>
            <Col
                sm={12}
                md={layout === "stacked" ? 12 : 3}
                className="column center"
            >
                <a
                    href={project.url}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm btn-outline-primary"
                    style={{ fontSize: "14pt" }}
                >
                    View Project
                </a>
            </Col>
        </Row>
    );
};
