import { useRef } from "react";
import { useIsInViewport } from "../../hooks/useIsInViewport";

import "./AnimatedSection.scss";

type Props = {
    id: string;
    children: JSX.Element;
};

export const AnimatedSection: React.FC<Props> = ({ id, children }) => {
    const sectionRef = useRef<any>(null);

    const isInViewport = useIsInViewport(sectionRef);

    let classNames = `animated ${isInViewport ? "shown" : "hidden"}`;

    return (
        <section id={id} className={classNames} ref={sectionRef}>
            {children}
        </section>
    );
};
