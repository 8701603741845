import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DynamicPage } from "../../@types/dynamicPage";

const NavSlice = createSlice({
    name: "NavigationSlice",
    initialState: {
        activePage: {} as DynamicPage,
    },
    reducers: {
        setActivePage(state: any, action: PayloadAction<string>) {
            state.activePage = JSON.parse(action.payload);
        },
    },
    extraReducers: (builder) => {},
});

export const { setActivePage } = NavSlice.actions;

export default NavSlice.reducer;
