import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import { PlaceHolder } from "../shared/PlaceHolder";

import "../styles/Intro.scss";

export const Intro: React.FC = () => {
    useEffect(() => {}, []);

    const scrollToSection2 = (e: any, sectionId: string) => {
        const domMsgById = document.getElementById(sectionId);
        if (domMsgById) {
            setTimeout(() => {
                window.scrollTo({
                    top: domMsgById?.offsetTop - 80,
                    behavior: "smooth",
                });
            }, 100);
        }
    };

    return (
        <PlaceHolder
            height={"calc(100vh - 150px)"}
            image={
                "https://tcdicontent.blob.core.windows.net/tcdioss/content/images/1018-blur-grayscale.jpg"
            }
            bgAttachment={isMobile ? "unset" : "fixed"}
            heading={
                <section id="headline" className="flex center vcenter">
                    <Row style={{ zIndex: 100 }}>
                        <Col md={12}>
                            <h1 className="title white bold">
                                Open Source,
                                <br />
                                Concepts, & Design
                            </h1>
                        </Col>
                        <Col md={12} className="mt-5">
                            <a
                                href="/#project_summary"
                                onClick={(e) =>
                                    scrollToSection2(e, "project_summary")
                                }
                                className="btn btn-lg get-started"
                            >
                                Get Started
                            </a>
                        </Col>
                    </Row>
                </section>
            }
        />
    );
};
