import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Contributor } from "../../@types/githubContribor";
import { Project } from "../../@types/project";
import { TeamMember } from "../../@types/teamMember";

const DataSlice = createSlice({
    name: "DataSlice",
    initialState: {
        projects: [] as Array<Project>,
        teamMembers: [] as Array<TeamMember>,
        contributors: [] as Array<Contributor>,
    },
    reducers: {
        setProjects(state: any, action: PayloadAction<string>) {
            state.projects = JSON.parse(action.payload);
        },
        setTeamMembers(state: any, action: PayloadAction<string>) {
            state.teamMembers = JSON.parse(action.payload);
        },
        setContributors(state: any, action: PayloadAction<string>) {
            state.contributors = JSON.parse(action.payload);
        },
    },
    extraReducers: (builder) => {},
});

export const { setProjects, setTeamMembers, setContributors } =
    DataSlice.actions;

export default DataSlice.reducer;
