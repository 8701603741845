import { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { BlogPost } from "../../@types/blogPost";

import { contentRoot, getAllBlogs } from "../../apiClients/contentApiClient";
import { PlaceHolder } from "../shared/PlaceHolder";
import { BlogPreview } from "./BlogPreview";

import "../styles/DisplayVlogPost.scss";

type PostParams = {
    postName: string;
};

export const DisplayBlogPost: React.FC = () => {
    const { postName } = useParams<PostParams>();

    const [allBlogPosts, setAllBlogPosts] = useState<Array<BlogPost>>([]);
    const [blogPost, setBlogPost] = useState<BlogPost | null>(null);
    const [blogContent, setBlogContent] = useState<string>("");

    const loadData = async () => {
        let bp = await getAllBlogs();
        setAllBlogPosts(bp);

        let post = bp.find((x) => x.id === postName);
        setBlogPost(post as BlogPost);
        let contentUrl = `${contentRoot}${post?.contentFile as string}`;
        fetch(contentUrl)
            .then((res) => res.text())
            .then((text) => setBlogContent(text));
    };

    useEffect(() => {
        // Get all blogs
        loadData();
    }, [postName]);

    return (
        <section id="main_content" className="main-page">
            <PlaceHolder
                image={
                    `${contentRoot}${blogPost?.bannerImgUrl as string}` ?? ""
                }
                title="Blog"
                subTitle={blogPost?.title}
                height={"500px"}
                heading={
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <h1 className="title white">Blog</h1>
                        <h2 className="white">{blogPost?.title}</h2>
                        <hr
                            style={{
                                color: "#FFFFFF",
                                height: 5,
                                width: 150,
                                marginLeft: "auto",
                                marginRight: "auto",
                                opacity: 1,
                                borderRadius: 10,
                            }}
                        />
                        <h4 className="white">
                            {blogPost?.author} | {blogPost?.publishedDate}
                        </h4>
                    </div>
                }
            />
            {blogPost && (
                <Container>
                    <div className="display-blog">
                        <div className="return-header">
                            <Link to="/blog">Return to Blogs & Vlogs</Link>
                        </div>
                        <div className="blog-main">
                            <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                                children={blogContent}
                            />
                        </div>
                        <hr />
                        <h3>Other Featured Blogs</h3>
                        <section className="blog-row">
                            {allBlogPosts
                                ?.filter((x) => x.id !== blogPost.id)
                                .map((post, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <BlogPreview
                                                post={post}
                                                index={index}
                                                mt={20}
                                                maxHeight="335px"
                                                maxWidth="400px"
                                            />
                                        </Fragment>
                                    );
                                })}
                        </section>
                    </div>
                </Container>
            )}
        </section>
    );
};
