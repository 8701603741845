import { isMobile } from "react-device-detect";

import { AnimatedSection } from "../shared/AnimatedSection";
import { PlaceHolder } from "../shared/PlaceHolder";

import { BlogSummary } from "./BlogSummary";
import { ProjectSummary } from "./ProjectSummary";
import { Intro } from "./Intro";
import { Vision } from "./Vision";

import "../styles/Home.scss";

export function Home(props: any) {
    return (
        <section id="mainPage" className="main-page">
            <Intro />
            <AnimatedSection id="vision">
                <Vision />
            </AnimatedSection>
            <AnimatedSection id="blog_summary">
                <BlogSummary />
            </AnimatedSection>
            <PlaceHolder
                height={"500px"}
                image={
                    "https://tcdicontent.blob.core.windows.net/tcdioss/content/images/1018-blur-grayscale.jpg"
                }
                bgAttachment={isMobile ? "unset" : "fixed"}
            />
            <AnimatedSection id="project_summary">
                <ProjectSummary />
            </AnimatedSection>
        </section>
    );
}
